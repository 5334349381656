$(function () {
    $('#currency-dropdown').on('change', function () {
        var c = $(this).val();

        $.ajax({
            url: '/currency/ajax/changeCurrency/' + c,
            success: function (r) {
                location.reload();
            }
        });
    });

    function fixateMap() {
        var headerHeight = $('.header-nav-main').height();
        var distanceFromTop = $('.sticky-div').offset().top;
        var scrolledFromTop = $(window).scrollTop();

        $('.map-container').css('height', 'calc(100vh - ' + headerHeight + 'px)');
        $('.sticky-div').css('height', 'calc(100vh - ' + headerHeight + 'px)');

        if (scrolledFromTop > distanceFromTop) {
            $('.sticky-div').css('position', 'sticky').css('top', headerHeight);
        }
    }

    if ($('#property-group-view').length > 0 && $('.sticky-div').length > 0) {
        addEventListener('scroll', fixateMap)
    }

    if ($('body').hasClass('homepage')) {
        $.ajax({
            url: $('#load-wrapper-homepage').attr('data-path') + '?limit=12&specialOffer=1&order=RAND_asc',
            success: function (r, status, jqXHR) {
                $('.preloader').hide();
                $('#load-wrapper-homepage').append(r).fadeIn();
                settingCardImagesDimensions();

                $('.card-gallery').slick({
                    'prevArrow': '<span class="slick-prev slick-nav"><i class="fas fa-angle-left"></i></span>',
                    'nextArrow': '<span class="slick-next slick-nav"><i class="fas fa-angle-right"></i></span>',
                });
            }
        });
    }

    // setting property detailed description containers
    if ($('body').hasClass('property')) {
        var wrapper = $('.section.property-section.bg.property-information #property-info-wrapper');
        var children = wrapper.children();

        for (let i = 0; i < children.length; i += 2) {
            children[i].classList.add('col-md-7');
        }

        for (let i = 1; i < children.length; i += 2) {
            children[i].classList.add('col-md-5');
        }

        if (window.matchMedia('(max-width: 576px)').matches) {
            $(children.last().removeClass('mb-5'));
        }
    }

    $('#forgotten-password form').on('submit', function (e) {
        e.preventDefault();

        let form = $(e.currentTarget);

        $.ajax({
            url: form.attr('action'),
            method: 'POST',
            data: form.serialize(),
            success: function (r, status, jqXHR) {
                console.log('success > r', r);
                $('#forgotten-password .forgt-pass-success').removeClass('d-none');

                setTimeout(function() {
                $('#forgotten-password').removeClass('show');
                    $('.modal-backdrop').remove();
                }, 7000);
            },
            error: function (jqXHR) {
                console.log('error > jqXHR', jqXHR);
                $('#forgotten-password .forgt-pass-error').removeClass('d-none');
            }
        });
    });
});

// accordion button functionality
$('.accordion-btn').on('click', function () {
    let element = $('.property-description.excerpt');
    let height = element.prop('scrollHeight');
        if (element.hasClass('show')) {
            element.removeClass('show').css('height', 120);
        $('.fas.fa-angle-down').removeClass('rotate');
    } else {
            element.addClass('show').css('height', height + 20);
        $('.fas.fa-angle-down').addClass('rotate');
    }
});

window.settingCardImagesDimensions = function settingCardImagesDimensions() {
    var img = $('.card.property-card .card-heading img');
    img.on('load', function () {
        var imgWidth = $(this).width();
        var imgRatio = 1.5022;
        var imgHeight = imgWidth / imgRatio;

        $(this).height(imgHeight);
    });
}